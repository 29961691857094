import PropTypes from "prop-types";
import { Alert, Snackbar } from "@mui/material";

export const MessageSnackbar = (props) => {
  const { controller } = props;

  return (
    <Snackbar
      open={controller.open}
      autoHideDuration={6000}
      onClose={controller.handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={controller.handleClose}
        severity={controller.severity}
        elevation={6}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {controller.message}
      </Alert>
    </Snackbar>
  );
};

MessageSnackbar.propTypes = {
  controller: PropTypes.object.isRequired,
};
