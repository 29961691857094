export const awsExports = {
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_ZrIyWhEk1",
    userPoolWebClientId: "4bgc4qbbgc24u78ere2vfihq2p",
  },
  API: {
    endpoints: [
      {
        name: "snugfit-lab-api",
        endpoint: "https://g6vwzclq3l.execute-api.us-east-1.amazonaws.com/v1",
      },
    ],
  },
};
