import PropTypes from "prop-types";
import { AppBar, Tabs, Tab } from "@mui/material";
import AccountMultipleIcon from "mdi-material-ui/AccountMultiple";
import FileDocumentMultipleIcon from "mdi-material-ui/FileDocumentMultiple";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const SideNav = (props) => {
  const { tab, setTab, showOrders, showClinics } = props;

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <AppBar position="sticky" sx={{ height: "100vh" }}>
      <Tabs
        orientation="vertical"
        value={tab}
        onChange={handleChange}
        textColor="inherit"
        aria-label="Side Navigation"
        selectionFollowsFocus
      >
        {showClinics && (
          <Tab
            label="Clinics"
            icon={<AccountMultipleIcon />}
            iconPosition="start"
            {...a11yProps(0)}
          />
        )}
        {showOrders && (
          <Tab
            label="Orders"
            icon={<FileDocumentMultipleIcon />}
            iconPosition="start"
            {...a11yProps(1)}
          />
        )}
      </Tabs>
    </AppBar>
  );
};

SideNav.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  showOrders: PropTypes.bool,
  showClinics: PropTypes.bool,
};
