import PropTypes from "prop-types";
import { InputAdornment, OutlinedInput } from "@mui/material";
import Magnify from "mdi-material-ui/Magnify";

export const SearchBar = (props) => {
  const { handleSearch } = props;

  return (
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search..."
      onChange={(e) => {
        handleSearch(e.target.value);
      }}
      startAdornment={
        <InputAdornment position="start">
          <Magnify />
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  );
};

SearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};
