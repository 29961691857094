import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import DeleteIcon from "mdi-material-ui/Delete";
import ConfirmIcon from "mdi-material-ui/AccountCheck";

import { SeverityPill } from "./severity-pill";
import { DeleteClinicDialog } from "./delete-clinic-dialog";
import { ConfirmClinicDialog } from "./confirm-clinic-dialog";
import { useDialog } from "../hooks/use-dialog";

export const ClinicsTable = (props) => {
  const { clinics, updateClinics, snackbar } = props;
  const [selectedClinic, setSelectedClinic] = useState({});
  const apiRef = useGridApiRef();
  const deleteDialog = useDialog();
  const confirmDialog = useDialog();

  const columns = useMemo(() => {
    return [
      {
        field: "labName",
        headerName: "Registered Lab",
        valueGetter: (params) => params.row["custom:labName"],
        flex: 2,
      },
      { field: "name", headerName: "Clinic Name", flex: 2 },
      { field: "email", headerName: "Email", flex: 2 },
      { field: "location", headerName: "Location", flex: 2 },
      {
        field: "status",
        headerName: "Confirmation Status",
        renderCell: (params) => {
          let color = "warning";
          if (params.value === "CONFIRMED") {
            color = "success";
          } else if (params.value === "UNCONFIRMED") {
            color = "error";
          }
          return <SeverityPill color={color}>{params.value}</SeverityPill>;
        },
        flex: 1.5,
      },
      {
        field: "createAt",
        headerName: "Signed Up",
        type: "date",
        valueGetter: (params) => new Date(params.row.createDate),
        flex: 0.7,
      },
      {
        field: "actions",
        type: "actions",
        align: "left",
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete Clinic"
            onClick={() => {
              setSelectedClinic(params.row);
              deleteDialog.setOpen(true);
            }}
          />,
          ...(params.row.status === "UNCONFIRMED"
            ? [
                <GridActionsCellItem
                  icon={<ConfirmIcon color="primary" />}
                  label="Confirm Clinic"
                  onClick={() => {
                    setSelectedClinic(params.row);
                    confirmDialog.setOpen(true);
                  }}
                />,
              ]
            : []),
        ],
      },
    ];
  }, [deleteDialog, confirmDialog]);

  const onDeletionCompleted = (res, id) => {
    if (res.error) {
      console.warn("delete clinic failed: " + res.error);
      snackbar?.setSeverity("error");
      snackbar?.setMessage(res.error);
      snackbar?.setOpen(true);
    } else {
      // update the local clinics data
      const newClinics = [];
      clinics.forEach((clinic) => {
        if (clinic.sub !== id) {
          newClinics.push(clinic);
        }
      });
      updateClinics?.(newClinics);
      // also update the table to give the user direct feedback
      apiRef.current.updateRows([{ sub: id, _action: "delete" }]);
      snackbar?.setSeverity("success");
      snackbar?.setMessage("Delete the clinic successfully");
      snackbar?.setOpen(true);
    }
  };

  const onConfirmationCompleted = (res, id) => {
    if (res.error) {
      console.warn("confirm clinic failed: " + res.error);
      snackbar?.setSeverity("error");
      snackbar?.setMessage(res.error);
      snackbar?.setOpen(true);
    } else {
      // update the local clinics data
      const newClinics = [...clinics];
      const newClinic = newClinics.find((clinic) => clinic.sub === id);
      newClinic.status = "CONFIRMED";
      updateClinics?.(newClinics);
      snackbar?.setSeverity("success");
      snackbar?.setMessage("Confirm the clinic successfully");
      snackbar?.setOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      >
        <DataGrid
          apiRef={apiRef}
          rows={clinics}
          getRowId={(row) => row.sub}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          disableColumnFilter
          disableRowSelectionOnClick
        />
      </Box>
      <DeleteClinicDialog
        controller={deleteDialog}
        clinic={selectedClinic}
        onDeletionCompleted={onDeletionCompleted}
      />
      <ConfirmClinicDialog
        controller={confirmDialog}
        clinic={selectedClinic}
        onConfirmationCompleted={onConfirmationCompleted}
      />
    </>
  );
};

ClinicsTable.propTypes = {
  clinics: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateClinics: PropTypes.func,
  snackbar: PropTypes.object,
};
