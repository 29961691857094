import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { createTheme } from "./theme";
import { AuthGuard } from "./guards/auth-guard";
import { Dashboard } from "./dashboard";

export function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthGuard>
        <Box sx={{ height: "100%" }}>
          <Dashboard />
        </Box>
      </AuthGuard>
    </ThemeProvider>
  );
}
