import { useCallback, useEffect, useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import RefreshIcon from "mdi-material-ui/Refresh";
import dayjs from "dayjs";

import { SearchBar } from "./components/searchbar";
import { ClinicsTable } from "./components/clinics-table";
import { listUsers } from "./utils/remote-api";

export const Clinics = (props) => {
  const { snackbar } = props;
  const [clinics, setClinics] = useState([]);
  const [filteredClinics, setFilteredClinics] = useState([]);

  function downloadClinics() {
    // get registered clinics of the current lab
    listUsers().then((users) => {
      if (users.error) {
        snackbar.setSeverity("error");
        snackbar.setMessage(users.error);
        snackbar.setOpen(true);
        return;
      }
      if (!users.length || users.length <= 0) {
        snackbar.setSeverity("info");
        snackbar.setMessage("No clinic registered to the lab right now.");
        snackbar.setOpen(true);
        return;
      }

      const reorganisedUsers = reorganiseClinicsInfo(users);
      // console.log(reorganisedUsers);
      setClinics(reorganisedUsers);
      setFilteredClinics(reorganisedUsers);
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(downloadClinics, []);

  const handleSearchClinics = useCallback(
    (searchPhrase) => {
      const phrase = searchPhrase.toLowerCase();
      setFilteredClinics(
        clinics.filter(
          (clinic) =>
            phrase === "" ||
            clinic["custom:labName"].toLowerCase().includes(phrase) ||
            clinic.name.toLowerCase().includes(phrase) ||
            clinic.email.toLowerCase().includes(phrase) ||
            clinic.location.toLowerCase().includes(phrase) ||
            clinic.status.toLowerCase().includes(phrase) ||
            clinic.createAt.includes(phrase)
        )
      );
    },
    [clinics]
  );

  return (
    <Box component="main" sx={{ p: 2 }}>
      <Container maxWidth="100%">
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4" fontWeight="bold">
              Clinics
            </Typography>
            <div>
              <Button
                startIcon={<RefreshIcon />}
                variant="contained"
                onClick={downloadClinics}
              >
                Reload
              </Button>
            </div>
          </Stack>
          <SearchBar handleSearch={handleSearchClinics} />
          <ClinicsTable
            clinics={filteredClinics}
            updateClinics={setClinics}
            snackbar={snackbar}
          />
        </Stack>
      </Container>
    </Box>
  );
};

// extract and re-organise clinics info from the API response
function reorganiseClinicsInfo(users) {
  return users
    .map((user) => {
      const clinic = {
        createDate: user.UserCreateDate,
        status: user.UserStatus,
      };

      user.Attributes.forEach((attr) => {
        clinic[attr.Name] = attr.Value;
      });

      // format create time and location
      clinic.createAt = dayjs(clinic.createDate).format("MM/D/YYYY");
      clinic.location = formatLocation(clinic);

      return clinic;
    })
    .sort((a, b) => {
      // also sort the clinics by creation time
      const timeA = new Date(a.createDate).getTime();
      const timeB = new Date(b.createDate).getTime();
      // sort descending
      return timeB - timeA;
    });
}

function formatLocation(clinic) {
  let location = "";
  const city = clinic["custom:city"];
  const state = clinic["custom:state"];
  const country = clinic["custom:country"];
  if (city || state || country) {
    if (city) {
      location += city.trim();
    }
    location += ", ";

    if (state) {
      location += state.trim();
    }
    location += ", ";

    if (country) {
      location += country.trim();
    }
  }
  return location;
}
