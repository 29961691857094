import { createTheme as createMuiTheme } from "@mui/material";
import { createPalette } from "./create-palette";

export function createTheme() {
  const palette = createPalette();

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    palette,
    shape: {
      borderRadius: 8,
    },
  });
}
