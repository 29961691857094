import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import { enableReUpload } from "../utils/remote-api";

export const EnableRescanDialog = (props) => {
  const { controller, order, onUpdateCompleted } = props;

  async function enableRescan() {
    controller.setOpen(false);
    const res = await enableReUpload(order.orderId);
    onUpdateCompleted?.(res, order.orderId);
  }

  return (
    <Dialog open={controller.open} onClose={controller.handleClose}>
      <DialogTitle>Enable the customer to re-upload scan models?</DialogTitle>
      <DialogActions>
        <Button onClick={enableRescan}>Enable</Button>
      </DialogActions>
    </Dialog>
  );
};

EnableRescanDialog.propTypes = {
  controller: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onUpdateCompleted: PropTypes.func,
};
