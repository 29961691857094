import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;

  const [email, setEmail] = useState("");
  const [labName, setLabName] = useState("Unknown");

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setEmail(user.attributes.email);
      setLabName(user.attributes["custom:labName"]);
    });
  }, []);

  const handleSignOut = useCallback(async () => {
    onClose?.();
    await Auth.signOut();
  }, [onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
          minWidth: 200,
        }}
      >
        <Box>
          <Typography variant="overline" fontWeight="bold">
            Account
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {email}
          </Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography variant="overline" fontWeight="bold">
            Connected Lab
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {labName}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
