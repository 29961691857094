import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Auth } from "aws-amplify";

import { TopNav } from "./components/top-nav";
import { SideNav } from "./components/side-nav";
import { Clinics } from "./clinics";
import { Orders } from "./orders";
import { MessageSnackbar } from "./components/message-snackbar";
import { useSnackbar } from "./hooks/use-snackbar";

export const Dashboard = () => {
  const [tab, setTab] = useState(0);
  const [showOrders, setShowOrders] = useState(false);
  const [showClinics, setShowClinics] = useState(false);
  const snackbar = useSnackbar();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      // console.log(user.attributes)
      setShowOrders(user.attributes["custom:showOrders"] === "true");
      setShowClinics(user.attributes["custom:showClinics"] === "true");
    });
  }, []);

  return (
    <>
      <Grid container>
        <Grid xs={1} md={2} lg={1}>
          <SideNav
            tab={tab}
            setTab={setTab}
            showOrders={showOrders}
            showClinics={showClinics}
          />
        </Grid>
        <Grid xs={11} md={10} lg={11}>
          <TopNav />
          <CurrentTabView
            tab={tab}
            showOrders={showOrders}
            showClinics={showClinics}
            snackbar={snackbar}
          />
        </Grid>
      </Grid>
      <MessageSnackbar controller={snackbar} />
    </>
  );
};

const CurrentTabView = (props) => {
  const { tab, showOrders, showClinics, snackbar } = props;
  if (showOrders && showClinics) {
    if (tab === 0) {
      return <Clinics snackbar={snackbar} />;
    } else {
      return <Orders snackbar={snackbar} />;
    }
  } else if (!showOrders && showClinics) {
    return <Clinics snackbar={snackbar} />;
  } else if (showOrders && !showClinics) {
    return <Orders snackbar={snackbar} />;
  } else {
    return (
      <Box component="main" sx={{ p: 2 }}>
        <Container maxWidth="100%">
          <Typography>
            You don't have permissions to manage any data of the lab.
          </Typography>
          <Typography mt={1}>
            Please <a href="mailto:info@snugfitsolutions.com">contact us</a>
          </Typography>
        </Container>
      </Box>
    );
  }
};
