import { Avatar, Box, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";

import { AccountPopover } from "./account-popover";
import { usePopover } from "../hooks/use-popover";

const TOP_NAV_HEIGHT = 64;

export const TopNav = () => {
  const accountPopover = usePopover();

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: 0,
          top: 0,
          width: "100%",
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Avatar
            onClick={accountPopover.handleOpen}
            ref={accountPopover.anchorRef}
            sx={{
              bgcolor: (theme) => theme.palette.primary.main,
              cursor: "pointer",
              height: 35,
              width: 35,
            }}
          />
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};
