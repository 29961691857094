import { useCallback, useState } from "react";

export function useSnackbar() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  // severity can be one of: "success", "error", "info", "warning"
  const [severity, setSeverity] = useState("success");

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    setOpen,
    handleOpen,
    handleClose,
    message,
    setMessage,
    severity,
    setSeverity,
  };
}
