import { useCallback, useEffect, useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import RefreshIcon from "mdi-material-ui/Refresh";
import dayjs from "dayjs";

import { SearchBar } from "./components/searchbar";
import { OrdersTable } from "./components/orders-table";
import { listOrders } from "./utils/remote-api";

export const Orders = (props) => {
  const { snackbar } = props;
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  function downloadOrders() {
    // get all placed orders of the current lab
    listOrders().then((ordersData) => {
      if (ordersData.error) {
        snackbar.setSeverity("error");
        snackbar.setMessage(ordersData.error);
        snackbar.setOpen(true);
        return;
      }
      if (!ordersData.length || ordersData.length <= 0) {
        snackbar.setSeverity("info");
        snackbar.setMessage("No order is placed right now.");
        snackbar.setOpen(true);
        return;
      }

      const formattedOrders = formatOrders(ordersData);
      // console.log(formattedOrders);
      setOrders(formattedOrders);
      setFilteredOrders(formattedOrders);
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(downloadOrders, []);

  const handleSearchOrders = useCallback(
    (searchPhrase) => {
      const phrase = searchPhrase.toLowerCase();
      setFilteredOrders(
        orders.filter(
          (order) =>
            phrase === "" ||
            order.origin.toLowerCase().includes(phrase) ||
            order.practiceName.toLowerCase().includes(phrase) ||
            order.detail?.toLowerCase().includes(phrase) ||
            order.webOrderId?.toLowerCase().includes(phrase) ||
            order.submitAt.includes(phrase)
        )
      );
    },
    [orders]
  );

  return (
    <Box component="main" sx={{ p: 2 }}>
      <Container maxWidth="100%">
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4" fontWeight="bold">
              Orders
            </Typography>
            <div>
              <Button
                startIcon={<RefreshIcon />}
                variant="contained"
                onClick={downloadOrders}
              >
                Reload
              </Button>
            </div>
          </Stack>
          <SearchBar handleSearch={handleSearchOrders} />
          <OrdersTable
            orders={filteredOrders}
            updateOrders={setOrders}
            snackbar={snackbar}
          />
        </Stack>
      </Container>
    </Box>
  );
};

function formatOrders(orders) {
  return orders
    .map((order) => {
      addOrderOrigin(order);
      addOrderDetail(order);
      order.submitAt = dayjs(order.submitTime).format("MM/D/YYYY");
      return order;
    })
    .sort((a, b) => {
      // sort the orders by submit time
      const timeA = new Date(a.submitTime).getTime();
      const timeB = new Date(b.submitTime).getTime();
      // sort descending
      return timeB - timeA;
    });
}

function addOrderOrigin(order) {
  switch (order.practiceId) {
    case "anonymous":
    case "deeplink-user":
      order.origin = "iOS Deeplink";
      break;
    case "sdk-user":
      order.origin = "SnugFit SDK";
      break;
    case "weblink-user":
      order.origin = "Online Store";
      break;
    default:
      order.origin = "iOS App";
  }
}

function addOrderDetail(order) {
  if (order.scanTypes) {
    // gather the scan types info
    const scanTypes = {};
    order.scanTypes.forEach(({ type, number }) => {
      if (scanTypes[type]) {
        scanTypes[type] += number;
      } else {
        scanTypes[type] = number;
      }
    });

    order.detail = "Scan types: ";
    Object.keys(scanTypes).forEach((type) => {
      order.detail += `${scanTypes[type]} ${type}, `;
    });
    order.detail = order.detail.slice(0, -2);

    if (order.webOrderDetails) {
      order.detail += "\nOrder detail: " + order.webOrderDetails;
    }
  } else if (order.webOrderDetails) {
    order.detail = order.webOrderDetails;
  }
}
