import { API, Auth } from "aws-amplify";

export async function listUsers() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    // console.log(user.attributes)
    const labId = user.attributes["custom:labId"];
    if (!labId) {
      return {
        error:
          "Failed to retrieve clinics, contact SnugFit to check your account settings",
      };
    }

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const res = await API.get("snugfit-lab-api", "/practice", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { id: labId },
    });
    return res;
  } catch (err) {
    console.error(err);
    return {
      error: err.message,
    };
  }
}

export async function deleteUser(id) {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const res = await API.del("snugfit-lab-api", "/practice", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { id },
    });
    return res;
  } catch (err) {
    console.error(err);
    return {
      error: err.message,
    };
  }
}

export async function confirmUser(id) {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const res = await API.put("snugfit-lab-api", "/practice", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { id },
    });
    return res;
  } catch (err) {
    console.error(err);
    return {
      error: err.message,
    };
  }
}

export async function listOrders() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    // console.log(user.attributes)
    const labId = user.attributes["custom:labId"];
    if (!labId) {
      return {
        error:
          "Failed to retrieve orders, contact SnugFit to check your account settings",
      };
    }

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const res = await API.get("snugfit-lab-api", "/order", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { id: labId },
    });
    return res;
  } catch (err) {
    console.error(err);
    return {
      error: err.message,
    };
  }
}

export async function enableReUpload(id) {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    await API.put("snugfit-lab-api", "/order", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { id },
    });
    return {};
  } catch (err) {
    console.error(err);
    return {
      error: err.message,
    };
  }
}
