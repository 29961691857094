import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import UploadIcon from "mdi-material-ui/Upload";

import { SeverityPill } from "./severity-pill";
import { EnableRescanDialog } from "./enable-rescan-dialog";
import { useDialog } from "../hooks/use-dialog";

export const OrdersTable = (props) => {
  const { orders, updateOrders, snackbar } = props;
  const [selectedOrder, setSelectedOrder] = useState({});
  const rescanDialog = useDialog();

  const columns = useMemo(() => {
    return [
      {
        field: "origin",
        headerName: "Order Origin",
        renderCell: (params) => {
          let color;
          switch (params.value) {
            case "iOS Deeplink":
              color = "success";
              break;
            case "SnugFit SDK":
              color = "warning";
              break;
            case "Online Store":
              color = "info";
              break;
            default:
              color = "primary";
          }
          return <SeverityPill color={color}>{params.value}</SeverityPill>;
        },
        flex: 1.2,
      },
      { field: "practiceName", headerName: "Customer Name", flex: 2 },
      {
        field: "detail",
        headerName: "Order Detail",
        renderCell: (params) => {
          return (
            <Typography variant="body2">
              <pre style={{ fontFamily: "inherit" }}>{params.value}</pre>
            </Typography>
          );
        },
        flex: 3,
      },
      { field: "webOrderId", headerName: "Online Order ID", flex: 1.2 },
      {
        field: "allowReUpload",
        headerName: "Allow Rescan",
        renderCell: (params) => {
          if (params.value) {
            return <SeverityPill color="warning">yes</SeverityPill>;
          } else {
            return <SeverityPill color="success">no</SeverityPill>;
          }
        },
        flex: 1,
      },
      {
        field: "submitAt",
        headerName: "Submit At",
        type: "date",
        valueGetter: (params) => new Date(params.row.submitTime),
        flex: 0.7,
      },
      {
        field: "actions",
        type: "actions",
        align: "left",
        getActions: (params) => {
          if (
            params.row.practiceId === "weblink-user" &&
            !params.row.allowReUpload
          ) {
            return [
              <GridActionsCellItem
                icon={<UploadIcon color="primary" />}
                label="Enable Rescan for Online Order"
                onClick={() => {
                  setSelectedOrder(params.row);
                  rescanDialog.setOpen(true);
                }}
              />,
            ];
          } else {
            return [];
          }
        },
      },
    ];
  }, [rescanDialog]);

  const onUpdateCompleted = (res, id) => {
    if (res.error) {
      console.warn("enable order rescan failed: " + res.error);
      snackbar?.setSeverity("error");
      snackbar?.setMessage(res.error);
      snackbar?.setOpen(true);
    } else {
      // update the local orders data
      const newOrders = [...orders];
      const newOrder = newOrders.find((order) => order.orderId === id);
      newOrder.allowReUpload = true;
      updateOrders?.(newOrders);
      snackbar?.setSeverity("success");
      snackbar?.setMessage("Enable the order rescan successfully");
      snackbar?.setOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      >
        <DataGrid
          rows={orders}
          getRowId={(row) => row.orderId}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          disableColumnFilter
          disableRowSelectionOnClick
        />
      </Box>
      <EnableRescanDialog
        controller={rescanDialog}
        order={selectedOrder}
        onUpdateCompleted={onUpdateCompleted}
      />
    </>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateOrders: PropTypes.func,
  snackbar: PropTypes.object,
};
