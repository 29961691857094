import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import { confirmUser } from "../utils/remote-api";

export const ConfirmClinicDialog = (props) => {
  const { controller, clinic, onConfirmationCompleted } = props;

  async function confirmClinic() {
    controller.setOpen(false);
    const res = await confirmUser(clinic.sub);
    onConfirmationCompleted?.(res, clinic.sub);
  }

  return (
    <Dialog open={controller.open} onClose={controller.handleClose}>
      <DialogTitle>Confirm clinic "{clinic.email}"?</DialogTitle>
      <DialogActions>
        <Button onClick={confirmClinic}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmClinicDialog.propTypes = {
  controller: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  onConfirmationCompleted: PropTypes.func,
};
