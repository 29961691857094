import React from "react";
import {
  Authenticator,
  ThemeProvider,
  useTheme,
  View,
  Image,
  Text,
} from "@aws-amplify/ui-react";

export const AuthGuard = (props) => {
  const { children } = props;
  const { tokens } = useTheme();

  const theme = {
    name: "SnugFit Theme",
    tokens: {
      colors: {
        brand: {
          primary: {
            10: tokens.colors.green["10"],
            80: tokens.colors.green["60"],
            90: tokens.colors.green["80"],
            100: tokens.colors.green["90"],
          },
        },
      },
    },
  };

  const components = {
    Header() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="SnugFit Logo" src="assets/logo.png" />
        </View>
      );
    },

    Footer() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        components={components}
        loginMechanisms={["email"]}
        hideSignUp={true}
      >
        {() => children}
      </Authenticator>
    </ThemeProvider>
  );
};
